import React from "react";
import { connect } from "react-redux";
import { GetCardList } from "../../../store/actions/ewallet/accounts";

import { AvField } from 'availity-reactstrap-validation';

class CardTypeSelector extends React.Component {

    constructor(props) {
        super(props);
        this.state = {};

        this.handleChange = this.handleChange.bind(this)
    }

    componentDidMount() {
        this.props.GetCardList();
    }

    handleChange(event) {
        if (this.props.CardTypeSelector !== undefined) {
            this.props.CardTypeSelector(event)
        }
    }

    render() {

        return <AvField
            type="select"
            name={this.props.name}
            id={this.props.id}
            label={this.props.label}
            required={this.props.required}
            placeholder={this.props.placeholder}
            style={{width:'100%'}}
            onChange={this.handleChange} 
            value={this.props.value}
            disabled={this.props.disabled}
            className="copy-address form-control-lg"
        >
            <option value="">Select...</option>
            <option value="cash">CASH</option>
            {
                Array.from(this.props.data).map((objValue, index) =>
                    <option
                        value={objValue.id}
                        key={index}>
                        {objValue.description.toUpperCase()}
                    </option>
                )
            }
        </AvField>

    }

}

const mapStateToProps = (state) => {
    return {
        ...state.accounts
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        GetCardList: () => {
            dispatch(GetCardList())
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CardTypeSelector);
import React from "react";
import { connect } from "react-redux";
import { AvField } from 'availity-reactstrap-validation';
import countryList from 'react-select-country-list'

class NationalySelector extends React.Component {

    constructor(props) {
        super(props);
        this.options = countryList().getData()
 
        this.state = {
            options: this.options,
            value: null,
        }

        this.handleChange = this.handleChange.bind(this)
    }

    handleChange(event) {
        if (this.props.NationalySelector !== undefined) {
            this.props.NationalySelector(event)
        }
    }

    render() {

        return <AvField
            type="select"
            name={this.props.name}
            id={this.props.id}
            label={this.props.label}
            required={this.props.required}
            placeholder={this.props.placeholder}
            style={{width:'100%'}}
            onChange={this.handleChange} 
            value={this.props.value}
            disabled={this.props.disabled}
            className="input-bordered"
        >
            <option value="">Select country...</option>
            {
                Array.from(this.state.options).map((objValue, index) =>
                    <option
                        value={objValue.label}
                        key={index}>
                        {objValue.label}
                    </option>
                )
            }
        </AvField>

    }

}

const mapStateToProps = (state) => {
    return {
        ...state.accounts
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
      
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(NationalySelector);
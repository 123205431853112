import React, { Component } from "react";
import OtpInput from 'react-otp-input';
import { connect } from "react-redux";
import { withRouter } from 'react-router-dom';

import { AvForm, AvGroup, AvField } from 'availity-reactstrap-validation';
import { closeTransactionDialog } from '../../../store/actions/redux_actions';

import { SetWalletAccountLoading, DoWalletTransaction, DoBankTransaction, GetReceiverWalletAccount, confirmOTP, resendOTP, 
  otpHasErrored } from '../../../store/actions/ewallet/wallet';

import { UcFirst } from '../../components';

import { Modal, ModalBody, Label } from "reactstrap";
import { Row, Col, Button } from "react-bootstrap";
// import NumberFormat from 'react-number-format';

import LoadingOverlay from 'react-loading-overlay';

import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';

import Aux from "../../../hoc/_Aux";
import { Alert } from "reactstrap";
import _ from "lodash";

import CardTypeSelector from './CardTypeSelector';

import {
  setUserLoading, GetUserByCustomerNumber
} from "../../../store/actions/ewallet/users";

import * as actionTypes from "../../../store/actions/actions";

class TransactionModal extends Component {

    constructor(props) {
      super(props);
      this.handleSubmit = this.handleSubmit.bind(this);
      this.onChangeValue = this.onChangeValue.bind(this);

      this.form = React.createRef();
      this.state = {
        sourceAccountNumber: null,
        destinationAccountNumber: null,
        otp: '',
        searchByPhone: false,
        searchByAccount: true,
        userPlaceHolder: 'Enter Customer Number'
      }
    }

    handleOTPChange = otp => this.setState({ otp });

    onChangeValue(event) {
      if(event.target.value === "searchByPhone"){
        this.setState({ searchByPhone: true, searchByAccount: false, userPlaceHolder: 'Enter User Phone Number' });
      }else{
        this.setState({ searchByAccount: true, searchByPhone: false, userPlaceHolder: 'Enter Customer Number'  });
      }
    }

    confirmTransaction = () => {
        this.form.submit();
    }

    CardTypeSelector = (e) => {
      const cardId = e.target.value;
      this.setState({ [e.target.name]: cardId });
    }

    confirmOTP = () => {
      
      const otp = this.state.otp;
      const { user_action } = this.props;

      if(!_.isEmpty(otp)){
        this.props.SetWalletAccountLoading("Processing Request...");
        this.props.confirmOTP(otp, user_action);
        this.setState({ otp: '' });
      }else{
        this.props.otpHasErrored('Invalid OTP');
      }
      
    }

    resendOTP = () => {
      const { otp_sessionId } = this.props;

      this.props.SetWalletAccountLoading("Otp resending Request...");
      this.setState({ otp: '' }, () => {
        this.props.resendOTP(otp_sessionId.id);
      })
    }

    handleSubmit(event, errors, values) {

      let transasctionParams = _.omit(_.merge(this.state, values), ['breadcrumbTitle']);

      if (_.isEmpty(errors)) {

        const { user_action, searchedUser, paymentId } = this.props;

        if(this.state.cardId === 'cash'){
                                  
          // redirect to manual page
          this.props.history.push('/cashin-request');
          this.props.closeTransactionDialog();
          
        }else{

          confirmAlert({
            customUI: ({ onClose }) => {
                return (
                    <div className='custom-ui'>
                        <h1>Confirm your action</h1>
                        <p>Are you sure you want to <UcFirst text={user_action.toLowerCase().replace(/_/g, ' ')} />?</p>
                        <Button onClick={onClose} className='btn-icon m-r-5' variant={'outline-secondary'}>Cancel</Button>
                        <Button
                            onClick={() => {
                                
                                const { selectedWalletAccount } = this.props;
                                
                                const accountNumber = selectedWalletAccount.customer && selectedWalletAccount.customer.accountNumber;
                                transasctionParams.accountNumber = accountNumber.toString();
  
                                let payload = {};
  
                                if(["REQUEST"].includes(user_action.toUpperCase())){
                                  transasctionParams.sourceAccountNumber = searchedUser.accountNumber;
                                }else if(["TRANSFER"].includes(user_action.toUpperCase())){
                                  transasctionParams.destinationAccountNumber = searchedUser.accountNumber;
                                }
                                this.props.SetWalletAccountLoading("Processing Request...");
                                
                                // for payments
                                if(["AIRTIME", "TAXI", "UTILITY", "MERCHANT", "MICROFINANCE_LOAN"]
                                .includes(user_action.toUpperCase()))
                                {
                                  if(["MICROFINANCE_LOAN"].includes(user_action.toUpperCase())){
  
                                    payload.loanId = paymentId;
                                    payload.amount = transasctionParams.amount;
  
                                    this.props.DoBankTransaction(payload);
                                    
                                  }else{
                                    
                                    this.props.DoWalletTransaction(transasctionParams, 'PAY');
  
                                  }
                                }
                                else{ // ALL BANK PROCESS
                                    this.props.DoWalletTransaction(transasctionParams, user_action)
                                }
  
                                onClose();
                            }}
                            className='btn-icon' variant={'outline-info'}>Confirm</Button>
                    </div>
                );
            },
            closeOnEscape: false,
            closeOnClickOutside: false,
          });
          
        }

      }

    }
    
    onFieldChange = (e) =>{
      const val = e.target.value;
      this.setState({ [e.target.name]: val });
    }

    searchBeneficiary = (e) => {

      const { user_action } = this.props;

      if(!_.isEmpty(this.state.sourceAccountNumber) || 
       !_.isEmpty(this.state.destinationAccountNumber)) {

        this.props.setUserLoading();
        let searchBy = "searchByAccount";
        if(this.state.searchByPhone) searchBy = "searchByPhone";
        
        if(user_action.toUpperCase() === actionTypes.REQUEST){
          this.props.GetUserByCustomerNumber(this.state.sourceAccountNumber, searchBy)
        }
        else if(user_action.toUpperCase() === actionTypes.TRANSFER){
          this.props.GetUserByCustomerNumber(this.state.destinationAccountNumber, searchBy)
        }

      } else{

        confirmAlert({
          customUI: ({ onClose }) => {
            
                // let msgLabel = 
                //   user_action.toUpperCase() === actionTypes.REQUEST ? 
                //   "Source Account" : "Benefiary Account";

                return (
                  <div className='custom-ui'>
                      {/* <h1>Confirm Action...</h1>
                      <p>Would you like to select this account as <strong>{msgLabel}</strong></p> */}
                      <h1>Please enter customer account number.</h1>
                      <Button onClick={onClose} className='btn-icon m-r-5' variant={'outline-secondary'}>Cancel</Button>
                      {/* <Button
                      onClick={() => {
                          this.handleVerification();
                          onClose();
                      }}
                      className='btn-icon' variant={'outline-info'}> Yes, Verify! </Button> */}
                  </div>
                );
            },
            closeOnEscape: false,
            closeOnClickOutside: false,
        })
        
      }

    }

    componentDidUpdate = () =>{

      const { searchedUser, user_action, searchOnGoing } = this.props;
      if(! _.isEmpty(searchedUser) && searchOnGoing){
        
        confirmAlert({
          customUI: ({ onClose }) => {
            
                let msgLabel = 
                  user_action.toUpperCase() === actionTypes.REQUEST ? 
                  "Source Account" : "Benefiary Account";

                return (
                  <div className='custom-ui'>
                      <h1>{msgLabel}</h1>
                      <div className="token-overview-wrap">
                      {
                        !["kyc_pending"].includes(searchedUser.status) ? 
                          <Aux>
                            <div className="token-overview">
                              <div className="row">
                                <div className="col-md-6 col-sm-8">
                                    <div className="token-bonus token-bonus-sale">
                                      <span className="token-overview-title">{msgLabel}</span>
                                      <span className="token-overview-value bonus-on-sale">{searchedUser.kyc.firstname} {searchedUser.kyc.lastname}</span>
                                    </div>
                                </div>
                                <div className="col-md-6 col-sm-8">
                                    <div className="token-bonus token-bonus-amount">
                                      <span className="token-overview-title">Gender</span>
                                      <span className="token-overview-value bonus-on-amount">{ <UcFirst text={searchedUser.kyc.gender} /> }</span>
                                    </div>
                                </div>
                                <div className="col-md-6 col-sm-8">
                                    <div className="token-total"><span className="token-overview-title font-bold">Customer Number</span>
                                    <span className="token-overview-value token-total-amount text-primary"> { searchedUser.accountNumber } </span></div>
                                </div>
                              </div>
                            </div>
                            <div className="note note-plane note-danger note-sm pdt-1x pl-0">
                                {/* <p>In case you send a in a different {msgLabel}, please contant administrator</p> */}
                            </div>
                          </Aux>
                          :
                          <div className="note note-plane note-danger note-sm pdt-1x pl-0">
                              <p>Unable to proceed with request. Customer Account currently <UcFirst text={searchedUser.status.toLowerCase().replace(/_/g, ' ')} /></p>
                          </div>
                        } 
                      </div>

                      <Button onClick={() => { 
                        
                        this.props.ClearSearch()
                        onClose();

                      }} className='btn-icon m-r-5' variant={'outline-secondary'}>Close</Button>
                      {
                        !["kyc_pending"].includes(searchedUser.status.toLowerCase()) &&
                        <Button
                        onClick={() => {
                            this.props.ConfirmedSearch();
                            onClose();
                        }}
                        className='btn-icon' variant={'outline-info'}> Confirm {msgLabel} </Button>
                      }
                  </div>
                );
            },
            closeOnEscape: false,
            closeOnClickOutside: false,
        })
        
      }

    }

    render() {
        
        const { user, selectedWalletAccount, user_action, loading, loadingText, error, errorMsg, 
          requestSuccess, successMsg, searchOnGoing, otp_generated, otpHasError, otpErrorMsg } = this.props;

        const accountNumber = selectedWalletAccount.customer && selectedWalletAccount.customer.accountNumber;

        // let balance = selectedWalletAccount.customer && selectedWalletAccount.customer.balance;

        return (
          <Modal
            isOpen={this.props.openDialog}
            toggle={this.closeTransactionDialog}
            className={"modal-primary modal-dialog-centered"}
          >
                <a href="#!" onClick={() => { this.props.closeTransactionDialog() }} 
                className="modal-close" data-dismiss="modal" aria-label="Close"><em className="ti fa fa-close"></em></a>
                <ModalBody>

                <LoadingOverlay active={loading} spinner text={loadingText} >

                  {
                    !otp_generated ?
                    
                    <div className="popup-body p-0">
                      <h4 className="popup-title">Transaction - <UcFirst text={user_action.toLowerCase().replace(/_/g, ' ')} /></h4>
                      
                      <p>
                        Enter the amount, you would like to {user_action.toLowerCase().replace(/_/g, ' ')}. 
                      </p>

                      <div className="gaps-1x" />

                      <Alert color={'success'} isOpen={requestSuccess} toggle={this.props.dismissAccountError}> {successMsg} </Alert>
                      <Alert color={'danger'} isOpen={error} toggle={this.props.dismissAccountError}> {errorMsg} </Alert>
                      
                      <AvForm onSubmit={this.handleSubmit} ref={f => (this.form = f)}>
                      {
                        ["admin", "agent"].includes(user.role) &&
                        <Aux>
                          <Row>
                            <Col col={12}>
                                <h6 className="font-bold">{<UcFirst text={user_action.toLowerCase().replace(/_/g, ' ')} />} to the following Account</h6>
                                <div className="copy-wrap mgb-0-5x">
                                  <span className="copy-feedback" />
                                  <em className="fab fa-ethereum" />
                                  <input type="text" className="copy-address" defaultValue={accountNumber} disabled name="accountNumber"/>
                                  <button className="copy-trigger copy-clipboard" data-clipboard-text={accountNumber}>
                                    <em className="ti fa fa-user" />
                                  </button>
                                </div>
                            </Col>
                          </Row>
                          <div className="gaps-1x" />
                          <Row>
                            <Col col={12}>
                                <AvGroup className="input-item">
                                    <Label for="amount" className="input-item-label required">Transasction Amount</Label>
                                    <AvField name="amount" type="number" className="input-bordered form-control-lg" placeholder="0.00" required />
                                </AvGroup>
                            </Col>
                          </Row>
                        </Aux> 
                      }
                      {/* customer actions  */}
                      {
                        ["customer"].includes(user.role) &&
                        <Row>
                          {
                            ["TRANSFER"].includes(user_action.toUpperCase()) &&
                            <Aux>
                              <Col md={12}>
                                <h5 className="font-bold">Benefiary Account 
                                {!searchOnGoing ? 
                                  <span className={`dt-type-md badge badge-outline text-success badge-md m-l-10`}>  
                                      <i className={'feather icon-check-circle'} /> &nbsp; Confirmed
                                  </span>
                                  : ""}
                                </h5>
                                <h6 className="mgt-1-5x font-mid">Search Benefiary By:</h6>
                                <div>
                                  <ul className="pay-list guttar-20px">
                                    <li className="pay-item"><input onChange={this.onChangeValue} disabled={!searchOnGoing} checked={this.state.searchByAccount} type="radio" className="pay-check" name="search-by-option" id="account-number" value="searchByAccount"/><label className="pay-check-label" htmlFor="account-number"><i className="fa fa-id-card m-r-10" />Wallet Id</label></li>
                                    <li className="pay-item"><input onChange={this.onChangeValue} disabled={!searchOnGoing} checked={this.state.searchByPhone} type="radio" className="pay-check" name="search-by-option" id="phone-number" value="searchByPhone" /><label className="pay-check-label" htmlFor="phone-number"><i className="fa fa-phone-square-alt m-r-10" />Phone Number</label></li>
                                  </ul>
                                </div>
                                
                                <h6 className="font-bold">{this.state.userPlaceHolder} 
                                {
                                  this.state.searchByPhone === true ? <span className="p-l-5" style={{color: '#758698', fontSize: '12px'}}>(+27 XXXX XXX XX)</span> : ""
                                }
                                </h6>
                                <div className="copy-wrap mgb-0-5x">
                                  <span className="copy-feedback" />
                                  <em className="fab fa-ethereum" />
                                  <AvField name="destinationAccountNumber" 
                                  onChange={this.onFieldChange}
                                  disabled={!searchOnGoing}
                                  type="number" className="copy-address form-control-lg" placeholder={this.state.userPlaceHolder} required />
                                  <a href="#!" className="copy-trigger copy-clipboard" onClick={this.searchBeneficiary}>
                                    <em className="ti fa fa-search" />
                                  </a>
                                </div>
                              </Col>
                              <Col md={12}>
                                <h6 className="font-bold">Transaction Amount</h6>
                                <div className="copy-wrap mgb-0-5x">
                                  <span className="copy-feedback" />
                                  <em className="fab fa-ethereum" />
                                  <AvField name="amount" type="number" className="copy-address form-control-lg" placeholder="0.00" required />
                                  <a href="#!" className="copy-trigger copy-clipboard" >ZAR</a>
                                </div>
                              </Col>
                            </Aux>
                          }
                          {
                            ["REQUEST"].includes(user_action.toUpperCase()) &&
                            <Aux>
                              <Col md={12}>
                              {!searchOnGoing ? 
                                <span className={`dt-type-md badge badge-outline text-success badge-md m-l-10`}>  
                                  <i className={'feather icon-check-circle'} /> &nbsp; Confirmed
                                </span>
                              : ""}
                              <h6 className="mgt-1-5x font-mid">Search Benefiary By:</h6>
                                <div>
                                  <ul className="pay-list guttar-20px">
                                    <li className="pay-item"><input onChange={this.onChangeValue} disabled={!searchOnGoing} checked={this.state.searchByAccount} type="radio" className="pay-check" name="search-by-option" id="account-number" value="searchByAccount"/><label className="pay-check-label" htmlFor="account-number"><i className="fa fa-id-card m-r-10" />Wallet Id</label></li>
                                    <li className="pay-item"><input onChange={this.onChangeValue} disabled={!searchOnGoing} checked={this.state.searchByPhone} type="radio" className="pay-check" name="search-by-option" id="phone-number" value="searchByPhone" /><label className="pay-check-label" htmlFor="phone-number"><i className="fa fa-phone-square-alt m-r-10" />Phone Number</label></li>
                                  </ul>
                                </div>
                                <h6 className="font-bold">Source Account 
                                {
                                  this.state.searchByPhone === true ? <span className="p-l-5" style={{color: '#758698', fontSize: '12px'}}>(+27 XXXX XXX XX)</span> : ""
                                }
                                </h6>
                                <div className="copy-wrap mgb-0-5x">
                                  <span className="copy-feedback" />
                                  <em className="fab fa-ethereum" />
                                  <AvField name="sourceAccountNumber" 
                                  onChange={this.onFieldChange}
                                  disabled={!searchOnGoing}
                                  type="number" className="copy-address form-control-lg" placeholder={this.state.userPlaceHolder} required />
                                  <a href="#!" className="copy-trigger copy-clipboard" onClick={this.searchBeneficiary}>
                                    <em className="ti fa fa-search" />
                                  </a>
                                </div>
                              </Col>
                              <Col md={12}>
                                <h6 className="font-bold">Transaction Amount</h6>
                                <div className="copy-wrap mgb-0-5x">
                                  <span className="copy-feedback" />
                                  <em className="fab fa-ethereum" />
                                  <AvField name="amount" type="number" className="copy-address form-control-lg" placeholder="0.00" required />
                                  <a href="#!" className="copy-trigger copy-clipboard" >ZAR</a>
                                </div>
                              </Col>
                            </Aux>
                          }

                          {
                            ["DEPOSIT", "WITHDRAWAL"].includes(user_action.toUpperCase()) &&
                            <Aux>
                              <Col md={12}>
                                  <h6 className="font-bold">{<UcFirst text={user_action.toUpperCase() === "WITHDRAWAL" ? "withdraw" : user_action.toLowerCase() } />} to my Wallet Account</h6>
                                  <div className="copy-wrap mgb-0-5x">
                                    <span className="copy-feedback" />
                                    <em className="fab fa-ethereum" />
                                    <input type="text" className="copy-address" defaultValue={accountNumber} disabled name="accountNumber"/>
                                  </div>
                              </Col>
                              <Col md={12}>
                              
                                <h6 className="font-bold">
                                  <UcFirst text={user_action.toUpperCase() === "WITHDRAWAL" ? "transfer to bank account" : "source account" } />
                                </h6>
                                <div className="copy-wrap mgb-0-5x">
                                  <span className="copy-feedback" />
                                  <em className="fab fa-ethereum" />
                                  <CardTypeSelector 
                                    name="cardId"
                                    id="cardId"
                                    required
                                    CardTypeSelector={this.CardTypeSelector}
                                  />
                                </div>
                              </Col>
                              <Col md={12}>
                                <h6 className="font-bold">Transaction Amount</h6>
                                <div className="copy-wrap mgb-0-5x">
                                  <span className="copy-feedback" />
                                  <em className="fab fa-ethereum" />
                                  <AvField name="amount" type="number" className="copy-address form-control-lg" placeholder="0.00" required />
                                  <a href="#!" className="copy-trigger copy-clipboard" >ZAR</a>
                                </div>
                              </Col>
                            </Aux>
                          }

                          {/* Payments */}
                          {
                            ["AIRTIME", "TAXI", "UTILITY", "MERCHANT"]
                            .includes(user_action.toUpperCase()) &&
                            <Aux>
                              <Col md={12}>
                                  <h6 className="font-bold">{<UcFirst text={user_action.toLowerCase().replace(/_/g, ' ')} />} from the following Account</h6>
                                  <div className="copy-wrap mgb-0-5x">
                                    <span className="copy-feedback" />
                                    <em className="fab fa-ethereum" />
                                    <input type="text" className="copy-address" defaultValue={accountNumber} disabled name="accountNumber"/>
                                  </div>
                              </Col>
                              <Col md={12}>
                                <h6 className="font-bold">Exact Amount</h6>
                                <div className="copy-wrap mgb-0-5x">
                                  <span className="copy-feedback" />
                                  <em className="fab fa-ethereum" />
                                  <AvField name="amount" type="number" className="copy-address form-control-lg" placeholder="0.00" required />
                                  <AvField name="type" type="hidden" className="" value={user_action.toLowerCase().replace(/_/g, ' ')} required />
                                </div>
                              </Col>

                              <Col md={12}>
                                <Alert color={'light'} isOpen={true} ><strong>  <em className="fas fa-info-circle" /> Important</strong>: Processing your bill may take up to 3 business days.</Alert>
                              </Col>

                            </Aux>
                          }

                          {
                             ["MICROFINANCE_LOAN", "MICROFINANCE_SAVING"].includes(user_action.toUpperCase()) &&
                            <Aux>
                              <Col md={12}>
                                  <h6 className="font-bold">Payment from the following Account</h6>
                                  <div className="copy-wrap mgb-0-5x">
                                    <span className="copy-feedback" />
                                    <em className="fab fa-ethereum" />
                                    <input type="text" className="copy-address" defaultValue={accountNumber} disabled name="accountNumber"/>
                                  </div>
                              </Col>
                              <Col md={12}>
                                <h6 className="font-bold">Exact Amount</h6>
                                <div className="copy-wrap mgb-0-5x">
                                  <span className="copy-feedback" />
                                  <em className="fab fa-ethereum" />
                                  <AvField name="amount" type="number" className="copy-address form-control-lg" placeholder="0.00" required />
                                  <AvField name="type" type="hidden" className="" value={user_action.toLowerCase().replace(/_/g, ' ')} required />
                                </div>
                              </Col>

                              <Col md={12}>
                                <Alert color={'light'} isOpen={true} ><strong>  <em className="fas fa-info-circle" /> Important</strong>: Processing your bill may take up to 3 business days.</Alert>
                              </Col>

                            </Aux>
                          }
                        </Row>
                      }
                      </AvForm>

                      {/* <ul className="pay-info-list row">
                        <li className="col-sm-6"><span>Balance:</span> 
                          <NumberFormat 
                              fixedDecimalScale
                              decimalScale={2}
                              value={Number(balance, 0)} 
                              displayType={'text'} thousandSeparator={true} 
                              prefix={''} 
                          />
                        </li>
                        <li className="col-sm-6">
                          <span>Transfer limit:</span> 50,0000
                        </li>
                      </ul> */}
                          
                          {
                            ["TRANSFER", "REQUEST"].includes(user_action.toUpperCase()) ?

                              <button className="btn btn-primary m-t-10" data-dismiss="modal" data-toggle="modal" 
                                disabled={requestSuccess || searchOnGoing}
                                data-target="#pay-confirm" onClick={this.confirmTransaction}>
                                { <UcFirst text={user_action.toLowerCase().replace(/_/g, ' ')} /> }
                                <em className="ti fa fa-arrow-right mgl-4-5x" />
                              </button> 
                              :

                              <button className="btn btn-primary m-t-10" data-dismiss="modal" data-toggle="modal" 
                              disabled={requestSuccess}
                              data-target="#pay-confirm" onClick={this.confirmTransaction}>
                              { <UcFirst text={user_action.toLowerCase().replace(/_/g, ' ')} /> }
                              <em className="ti fa fa-arrow-right mgl-4-5x" />
                              </button>
                          }

                      <div className="gaps-3x" />
                      <div className="note note-plane note-light mgb-1x">
                        <em className="fas fa-info-circle" />
                        <p>Do not make payment through unauthorize agent. You can use EWallet portal.</p>
                      </div>
                      <div className="note note-plane note-danger">
                        <em className="fas fa-info-circle" />
                        <p>In case you send a different amount, please contant administrator</p>
                      </div>
                      </div>
                    
                    : 
                    
                    <div className="popup-body p-0">

                      <h4 className="popup-title">One Time Password - <UcFirst text={user_action.toLowerCase().replace(/_/g, ' ')} /></h4>
                      <p>
                        Please use it within 5 minutes to complete {user_action.toLowerCase().replace(/_/g, ' ')} transaction. 
                      </p>
                      <div className="gaps-1x" />
                      {/* if otp was successfully generated then */}
                        <div className="row text-center">
                          <div className="col-md-12">
                            <Alert color={'danger'} isOpen={otpHasError} toggle={this.props.dismissAccountError}> {otpErrorMsg} </Alert>
                          </div>
                          <OtpInput
                            value={this.state.otp}
                            onChange={this.handleOTPChange}
                            numInputs={6}
                            separator={<span>-</span>}
                            className="inputStyle"
                          />
                        </div>
                        <div className="gaps-1x" />
                        <div className="note note-plane note-danger">
                          <em className="fas fa-info-circle" />
                          <p>Never share this OTP with anyone.</p>
                        </div>
                        <div className="gaps-1x" />
                        <button className="btn btn-primary m-t-10 m-r-10" data-dismiss="modal" data-toggle="modal" 
                          data-target="#otp-confirm" onClick={this.confirmOTP.bind(this)}>
                          { <UcFirst text={user_action.toLowerCase().replace(/_/g, ' ')} /> }
                          <em className="ti fa fa-arrow-right mgl-4-5x" />
                        </button> 
                        <button className="btn btn-default m-t-10" data-dismiss="modal" data-toggle="modal" 
                          data-target="#otp-confirm" onClick={this.resendOTP.bind(this)}> Resend OTP
                          <em className="ti fa fa-arrow-right mgl-4-5x" />
                        </button> 
                    </div>
                  }   

                  </LoadingOverlay>
                </ModalBody>
          </Modal>
        );
      }
    
}

const mapStateToProps = state => {
    return {
        ...state.auth,
        ...state.redux_actions,
        ...state.ewallet,
        ...state.users
    };
  };

  const mapDispatchToProps = dispatch => {
    return {
      closeTransactionDialog: () => {
        dispatch({ type: "CLEAR_USER_SEARCH" });
        dispatch({ type: "CLEAR_EWALLET"});
        dispatch(closeTransactionDialog());
      },
      SetWalletAccountLoading: () => {
        dispatch(SetWalletAccountLoading());
      },
      DoWalletTransaction: (objParams, method) => {
        dispatch(DoWalletTransaction(objParams, method));
      },
      DoBankTransaction: (objParams) => {
        dispatch(DoBankTransaction(objParams));
      },
      GetReceiverWalletAccount: (accountId) => {
        dispatch(GetReceiverWalletAccount(accountId));
      },
      // user search
      setUserLoading: () => {
        dispatch(setUserLoading());
      },
      GetUserByCustomerNumber: (accountNumber, searchBy) => {
        dispatch(GetUserByCustomerNumber(accountNumber, searchBy));
      },
      ClearSearch: () => {
        dispatch({ type: "CLEAR_USER_SEARCH" });
      },
      ConfirmedSearch: () => {
        dispatch({ type: "CONFIRMED_USER_SELECTION" });
      },
      confirmOTP: (otp, actionMethod) => {
        dispatch(confirmOTP(otp, actionMethod));
      },
      resendOTP: (otp_sessionId) => {
        dispatch(resendOTP(otp_sessionId));
      },
      otpHasErrored: (bool) => {
        dispatch(otpHasErrored(bool));
      }
    };
  };

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(TransactionModal));
